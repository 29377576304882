// bin status
// ACTIVE = 'active' is already defined in warehouseConstants.js
// DEACTIVATED = 'deactivated' is already defined in warehouseConstants.js

// weight constants
export const EMPTY_BIN_WEIGHT_LBS = 4;
export const EMPTY_SHEET_TRAY_WEIGHT_LBS = 2;
// status reasons
export const WASTED = 'wasted';
export const REALLOCATED = 'reallocated';
export const EXPIRED = 'expired';

// waste reasons
export const BIN_EXPIRED = 'bin_expired';
export const QUALITY_ISSUE = 'quality_issue';
export const FOREIGN_OBJECT = 'foreign_object';
export const OVERPRODUCTION = 'overproduction';
export const BIN_EXPIRED_DUE_TO_OTHER_CI_SHORT =
  'bin_expired_due_to_other_ci_short';

export const WASTE_REASONS = [
  BIN_EXPIRED,
  QUALITY_ISSUE,
  FOREIGN_OBJECT,
  OVERPRODUCTION,
  BIN_EXPIRED_DUE_TO_OTHER_CI_SHORT,
];
