import { HC_GREEN, TRANQUIL_BLUE, HOT_PINK_RED, ROBOTO } from 'lib/constants';
import { drawerStyling } from 'lib/styles';

const actionButton = {
  fontWeight: '800',
  fontFamily: ROBOTO,
  fontSize: '17px',
  margin: '4px 8px',
  padding: '4px 24px',
  textTransform: 'uppercase',
  width: '100%',
};

export default {
  bodySection: {
    ...drawerStyling.bodySection,
    height: 'calc(100vh - 145px)',
    margin: '0px 16px',
  },
  buttons: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    justifySelf: 'end',
    marginLeft: 'auto',
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: '8px',
  },
  closeIcon: {
    width: 20,
    height: 20,
  },
  confirmButton: {
    marginLeft: '8px',
  },
  drawer: {
    width: '100%',
    fontFamily: ROBOTO,
  },
  formButtons: {
    display: 'flex',
    justifyContent: 'end',
    marginTop: '24px',
    spaceBetween: '8px',
  },
  formContent: {
    fontFamily: ROBOTO,
    fontSize: '18px',
    fontWeight: '400',
    lineHeight: '20px',
    marginTop: '24px',
  },
  formHeader: {
    fontFamily: ROBOTO,
    fontSize: '20px',
    fontWeight: 900,
    lineHeight: '32px',
  },
  formSubheader: {
    fontFamily: ROBOTO,
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '20px',
  },
  drawerButtonContainer: {
    display: 'flex',
    justifyContent: 'end',
    padding: '16px 24px 24px 24px',
  },
  drawerSubmitButton: {
    padding: '8px 22px 8px 22px',
    fontFamily: ROBOTO,
  },
  drawerSubmitButtonWrapper: {
    width: '164px',
    height: '42px',
  },
  entryContainer: {
    padding: '24px',
    top: '64px',
    gap: '48px',
    flexGrow: 3,
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  questionContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '10px',
  },
  questions: {
    paddingTop: '30px',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '10px',
    gap: '16px',
  },
  questionText: {
    fontFamily: ROBOTO,
    fontWeight: 400,
  },
  drawerSelect: {
    width: '400px',
  },
  reAllocateButton: {
    ...actionButton,
    borderColor: TRANQUIL_BLUE,
    color: TRANQUIL_BLUE,
  },
  weightToRecipesContainer: {
    paddingLeft: '20px',
  },
  textFieldWrapper: {
    paddingTop: '10px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  topSection: {
    ...drawerStyling.topSection,
    margin: '24px 0px',
    justifyContent: 'end',
  },
  useBinButton: {
    ...actionButton,
    borderColor: HC_GREEN,
    color: HC_GREEN,
  },
  wasteButton: {
    ...actionButton,
    borderColor: HOT_PINK_RED,
    color: HOT_PINK_RED,
  },
};
