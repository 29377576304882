import {
  BORDER_GREY,
  HC_GREEN,
  GREY_150,
  GREY_600,
  GREY_800,
  ROBOTO,
} from 'lib/constants';

// Re-usable styles across this specific app
export const cell = {
  color: GREY_800,
  fontFamily: ROBOTO,
  fontSize: '14px',
  fontWeight: '400',
  padding: '16px',
};

export const clickableRows = {
  '&:hover': {
    cursor: 'pointer',
  },
};

export default {
  ...cell,
  cellLast: {
    ...cell,
    textAlign: 'right',
  },
  container: {
    border: `1px solid ${BORDER_GREY}`,
    borderRadius: 8,
    boxShadow: 'none',
  },
  header: {
    ...cell,
    fontSize: '12px',
    textTransform: 'uppercase',
    letterSpacing: '1px',
    color: GREY_600,
  },
  mealLetter: {
    ...cell,
    color: HC_GREEN,
    fontWeight: '500',
  },
  zebraRow: {
    '&:nth-child(odd)': {
      background: `${GREY_150}`,
    },
    '&:last-child td, &:last-child th': { border: 0 },
  },
  clickableRows: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
};
