import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { TextField, Typography, Button, MenuItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import isEmpty from 'lodash/isEmpty';

import { StatefulButton, SnackbarSimpleError } from 'components/shared';
import {
  REALLOCATED,
  REALLOCATING,
  DEACTIVATED,
  EMPTY_BIN_WEIGHT_LBS,
  EMPTY_SHEET_TRAY_WEIGHT_LBS,
} from 'lib/constants';
import useBins from 'lib/custom_hooks/useBins';
import { PrinterContext } from 'components/RthProductionSubmissions/PrinterProvider';

import BinFormDrawer from '../BinFormDrawer';
import styles from '../styles';

const useStyles = makeStyles(styles);

const buttonText = {
  DEFAULT: 'Allocate To New Meal',
  SUCCESS: 'Success!',
  LOADING: 'Saving...',
  ERROR: 'Error - Try Again',
};

export const ReallocateForm = ({ bin }) => {
  const classes = useStyles();
  const [open, toggleOpen] = useState(false);
  const [weightInLbs, setWeightInLbs] = useState('');
  const [allocatedMealProgressId, setAllocatedMealProgressId] = useState(null);
  const [trayQuantity, setTrayQuantity] = useState('');

  const { setPrinterDialogOpen, setPrintSubmission, setBinMode } = useContext(
    PrinterContext
  );

  const resetForm = () => {
    setWeightInLbs('');
    setAllocatedMealProgressId(null);
    setTrayQuantity('');
  };

  const validateTrayQuantity = (value) => {
    if (value <= 0) {
      return 'Tray quantity must be greater than 0';
    }
    return false;
  };

  const isTrayQuantityRequired = bin.isSauce && isEmpty(trayQuantity);
  const isInvalidTrayQuantity =
    isTrayQuantityRequired && !!validateTrayQuantity(trayQuantity);

  const submitDisabled =
    !allocatedMealProgressId || isEmpty(weightInLbs) || isInvalidTrayQuantity;

  const {
    onUpdateBin,
    submitting,
    submittingError,
    resetBinsErrors,
  } = useBins();

  const openModal = () => {
    resetBinsErrors();
    resetForm();
    toggleOpen(true);
  };

  const weightToRecipes = (
    coefficient,
    weight,
    containerBaseWeight,
    containerCount
  ) => {
    const weightOfProduct = weight - containerBaseWeight * containerCount;

    if (weightOfProduct <= 0) {
      return 0;
    }

    return (coefficient * weightOfProduct).toFixed(2);
  };

  const onReallocateBin = async () => {
    const params = {
      progressId: allocatedMealProgressId,
      statusReason: REALLOCATED,
      trayQuantity,
      weightInLbs,
    };

    const response = await onUpdateBin({ binId: bin.id, binParams: params });

    if (response.ok) {
      const binAssignment = response.bin.binAssignments.findLast(
        (ba) => ba.action === REALLOCATING
      );

      setPrintSubmission({
        binAssignmentIds: [binAssignment.id],
      });
      setBinMode(true);
      setPrinterDialogOpen(true);
    }
  };

  const renderWeightToRecipeConversion = () => {
    let containerBaseWeight;
    let containerCount;

    if (bin.isSauce) {
      containerBaseWeight = EMPTY_SHEET_TRAY_WEIGHT_LBS;
      containerCount = Number(trayQuantity);
    } else {
      containerBaseWeight = EMPTY_BIN_WEIGHT_LBS;
      containerCount = 1;
    }

    if (containerCount < 1 || weightInLbs <= 0) {
      return;
    }

    const recipes = weightToRecipes(
      bin.lbToRecipeCoeff,
      weightInLbs,
      containerBaseWeight,
      containerCount
    );

    const unit = recipes != 1 ? 'recipes' : 'recipe';

    return (
      recipes > 0 && (
        <div className={classes.weightToRecipesContainer}>
          {recipes} {unit}
        </div>
      )
    );
  };

  const renderFormInputs = () => (
    <>
      <Typography className={classes.formHeader}>
        Allocate to New Meal
      </Typography>
      <Typography className={classes.formSubheader}>
        Bin {bin.id} - {bin.ingredientName}
      </Typography>
      <div className={classes.questions}>
        <div className={classes.questionContainer}>
          <div className={classes.questionText}>Allocate to Meal:</div>
          <div className={classes.textFieldWrapper}>
            <TextField
              className={classes.drawerSelect}
              data-testid="reallocate-form-select"
              label="Select A Meal"
              onChange={(e) => setAllocatedMealProgressId(e.target.value)}
              select
              variant="outlined"
              value={allocatedMealProgressId || ''}
            >
              {bin.relatedMealsForIngredientForShipweek.map((meal) => (
                <MenuItem
                  key={`reallocate-form-option-${meal.mealId}`}
                  value={meal.mealProgressId}
                >
                  {meal.mealLetterAssignment} - {meal.mealName}
                </MenuItem>
              ))}
            </TextField>
          </div>
        </div>
        <div className={classes.questionContainer}>
          <div className={classes.questionText}>
            What is the weight in pounds (lbs)?
          </div>
          <div className={classes.textFieldWrapper}>
            <TextField
              label="Weight in Lbs"
              onChange={(e) => setWeightInLbs(e.target.value)}
              type="number"
              value={weightInLbs}
              variant="outlined"
            />
            {renderWeightToRecipeConversion()}
          </div>
          <div />
        </div>
        {bin.isSauce && (
          <div className={classes.questionContainer}>
            <div className={classes.questionText}>
              How many trays are being reallocated?
            </div>
            <div className={classes.textFieldWrapper}>
              <TextField
                error={
                  !isEmpty(trayQuantity) && !!validateTrayQuantity(trayQuantity)
                }
                helperText={trayQuantity && validateTrayQuantity(trayQuantity)}
                label="Tray Quantity"
                onChange={(e) => setTrayQuantity(e.target.value)}
                type="number"
                value={trayQuantity}
                variant="outlined"
              />
            </div>
          </div>
        )}
      </div>
    </>
  );

  return (
    <>
      <Button
        className={classes.reAllocateButton}
        variant="outlined"
        onClick={openModal}
        disabled={
          isEmpty(bin.relatedMealsForIngredientForShipweek) ||
          bin.status === DEACTIVATED
        }
      >
        Reallocate
      </Button>
      <BinFormDrawer
        open={open}
        toggleOpen={toggleOpen}
        data-testid={`reallocate-form-bin-${bin.id}`}
      >
        <div className={classes.formContainer}>
          <div className={classes.entryContainer}>{renderFormInputs()}</div>
          <div className={classes.drawerButtonContainer}>
            <StatefulButton
              buttonTextOptions={buttonText}
              classes={{ root: classes.drawerSubmitButton }}
              disabled={submitDisabled}
              failed={Boolean(submittingError)}
              loading={submitting}
              onClick={() => onReallocateBin(bin.id)}
              successCallback={() => toggleOpen(false)}
              type="submit"
              variant="contained"
            />
            <SnackbarSimpleError
              open={Boolean(submittingError)}
              message={submittingError}
            />
          </div>
        </div>
      </BinFormDrawer>
    </>
  );
};

ReallocateForm.propTypes = {
  bin: PropTypes.object.isRequired,
};

export default ReallocateForm;
