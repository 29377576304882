import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Loading, MenuNavigation } from 'components/shared';
import { ASSEMBLY } from 'lib/constants';

import MealsList from '../MealsList';
import styles from './styles';

const useStyles = makeStyles(styles);

const ActivityMeals = ({ mealRequestData, activity, fetchCallback }) => {
  const { menuId } = useParams();
  const { mealProgresses, fetching, failed } = mealRequestData;
  const classes = useStyles();

  useEffect(() => {
    menuId && fetchCallback(menuId);
  }, [menuId]);

  return (
    <div className={classes.container}>
      <MenuNavigation menusCount={3} />
      {failed && (
        <div className={classes.error}>Failed to fetch meal progresses.</div>
      )}
      {fetching && <Loading fullScreen />}
      {!failed && !fetching && (
        <MealsList mealProgresses={mealProgresses} activity={activity} />
      )}
    </div>
  );
};

ActivityMeals.propTypes = {
  mealRequestData: PropTypes.shape({
    mealProgresses: PropTypes.array,
    fetching: PropTypes.bool,
    failed: PropTypes.bool,
  }),
  activity: PropTypes.string,
  fetchCallback: PropTypes.func.isRequired,
};

ActivityMeals.defaultProps = {
  activity: ASSEMBLY,
  mealRequestData: {
    mealProgresses: [],
    fetching: false,
    failed: false,
  },
};

export default ActivityMeals;
